<script setup>
    // import ONavbar from 'o365.vue.components.ONavbar.vue';
    // import { getDataObjectById, getOrCreateProcedure, getOrCreateDataObject } from 'o365.vue.ts';
    // import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import { ref, inject, computed, watch, onMounted, isProxy, toRaw } from 'vue';
    // import context from 'o365.modules.Context.ts';
    // import $t from "o365.modules.translate.ts";
    import BIMViewer from 'lease.vue.components.BIMviewer.vue';
    // import { ToastType } from 'o365.modules.ToastService.ts';
    // import alert from 'o365.controls.alert.ts';
    // import ObjectLookup from "assets.vue.components.ObjectLookup.vue";    
    import { OObjectsLookup } from 'o365-system-lookups';

    import ONavbar from 'o365.vue.components.ONavbar.vue';
    import { useAsyncComponent } from 'o365-vue-utils';
    import { InjectionKeys } from 'o365-utils';
    import 'o365-data-properties';
    import { GlobalMessenger as messenger } from 'o365-modules';
    import { Url } from 'o365-utils';
    import { getOrCreateProcedure } from 'o365-modules';
    import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
    import { OOrgUnitsLookup, OPersonsLookup, OMilestonesLookup, OProductFeaturesLookup, OInterfacesLookup } from "o365-system-lookups";

    const props = defineProps({
        filter: {type: String, default: ""},
        lease_ID: Number,
        disableContextFilter: {type: Boolean, default: false},
        loadDataObject: Object,
        roomsDataObject: Object,
        hideUnavailable: {type: Boolean, default: false},
        hideStatusDate: {type: Boolean, default: false},
        domainID: Number
    });

    const dsRooms = props.roomsDataObject;
    const roomGrid = ref();
    const vModel_ID = ref(null);
    const vHideUnavailable = ref(props.hideUnavailable);
    const vHideStatusDate = ref(props.hideStatusDate);

    /* DATASOURCES */
    const dsConditionLevelLkp = getOrCreateDataObject({
        "id": "dsConditionLevelLkp" + "_" + crypto.randomUUID(),
        "viewName": "aviw_Assets_ConditionLevels",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "NameT"},{"name": "FCMRateFactor", "sortOrder": 1, "sortDirection": "desc"}]
    });

    const dsAreaTypeLkp = getOrCreateDataObject({
        "id": "dsAreaTypeLkp" + "_" + crypto.randomUUID(),
        "viewName": "atbv_Assets_AreaTypes",
        "distinctRows": false,
        "uniqueTable": "",
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "Name"},{"name": "IsLeasable", "type": "boolean"},{"name": "IsCommonAreaBuilding", "type": "boolean"},{"name": "IsCommonAreaStorey", "type": "boolean"}]
    });

    const dsPriceModelLkp = getOrCreateDataObject({
        "id": "dsPriceModelLkp" + "_" + crypto.randomUUID(),
        "viewName": "aviw_Assets_PriceModels",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 5,
        "dynamicLoading": false,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID", "sortOrder": 1, "sortDirection": "asc"},{"name": "Name"}]
    });

    const dsModels = getOrCreateDataObject({
        "id": "dsModels" + "_" + crypto.randomUUID(),
        "viewName": "aviw_BIM_Models",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": false,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "whereClause": "MarkedForDeletion=0",
        "filterString": null,
        "disableAutoLoad": true,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "OrgUnit_ID"},{"name": "Name"},{"name": "Longitude"},{"name": "Latitude"},{"name": "MarkedForDeletion"},{"name": "Description"},{"name": "StoreyFragmentDef_ID"},{"name": "IdPath"},{"name": "CurrentRevision_ID"}]
    });

    const dsClassificationLkp = getOrCreateDataObject({
        "id": "dsClassificationLkp" + "_" + crypto.randomUUID(),
        "viewName": "aviw_Assets_ClassificationsItems",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 500,
        "dynamicLoading": false,
        "whereClause": "Level=3 AND IsCurrent=1",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "Name"},{"name": "Title"},{"name": "NamePath"},{"name": "TitlePath"},{"name": "Parent"},{"name": "Type"},{"name": "Classification"},{"name": "Classification_ID"},{"name": "Level"},{"name": "ClassificationsItem0"},{"name": "ClassificationsItem1"},{"name": "ClassificationsItem2"},{"name": "ClassificationsItem3"},{"name": "ID"},{"name": "ClassificationsItem0_ID"},{"name": "ClassificationsItem1_ID"},{"name": "ClassificationsItem2_ID"},{"name": "ClassificationsItem3_ID"},{"name": "IsCurrent"}]
    });

    const dsRateCategoryLkp = getOrCreateDataObject({
        "id": "dsRateCategoryLkp" + "_" + crypto.randomUUID(),
        "viewName": "atbv_Assets_RateCategories",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "clientSideHandler": false,
        "maxRecords": 25,
        "dynamicLoading": false,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "fields": [{"name": "ID"},{"name": "Name"},{"name": "Rate", "type": "number", "sortOrder": 1}]
    });

    /* PROCEDURES */
    const procAddObjectsToLease = getOrCreateProcedure({id : "procAddObjectsToLease", procedureName : "astp_Lease_AddObjectsToLease"});

    /* VARIABLES */
    const vShow = ref(false);
    const vBimGroupByField = ref(null);
    const vBimGroupByFieldVal = ref(null);
    const vGroupColors = ref(null);
    const vRoomStatusDate = ref(null);

    /* VUE */
    watch(() => props.filter, (first, second) => {
        updateWhereClause();
    });

    watch(() => props.hideUnavailable, (first, second) => {
        console.log(1);
        vHideUnavailable.value = first;
    });

    watch(() => vHideUnavailable.value, (first, second) => {
        console.log(2);
        updateWhereClause();
    });

    watch(() => props.hideStatusDate, (first, second) => {
        console.log(1);
        vHideStatusDate.value = first;
    });

    onMounted(() => {
        props.disableContextFilter ? null : dsRooms.enableContextFilter(); 
        vRoomStatusDate.value = new Date();
        updateWhereClause();
    });

    /* FUNCTIONS */
    async function toggleBIMViewer() {
        if(dsModels.current?.ID) {
            vShow.value = !vShow.value;
        } else {
            alert($t('Please select a BIM-model'), ToastType.Warning);
        }
    }
 
    function updateWhereClause() {
        dsRooms.recordSource.whereClause = "Date='" + (vRoomStatusDate.value.getUTCFullYear().toString() + String("0" + (vRoomStatusDate.value.getUTCMonth()+1)).slice(-2) + String("0" + vRoomStatusDate.value.getUTCDate()).slice(-2)) + "'" 
                                        + (props.filter ? " AND " + props.filter : "") 
                                        + (vModel_ID.value ? " AND Model_ID = " + vModel_ID.value : "") 
                                        + (vHideUnavailable.value ? " AND Availability > 0" : "")
                                        + (props.domainID ? " AND Domain_ID = " + props.domainID : "");
        dsRooms.load();  
    }

    async function addSelectedRoomsToLease() {
        try {
            const vData = [];
            toRaw(dsRooms.selectionControl.selectedUniqueKeys).forEach(function name(params) { vData.push( [params] ); })
            const selectedObjects   = ref({
                OrgUnit_ID : props.lease_ID,
                Object_PrimKeys : vData
            });
            await procAddObjectsToLease.execute( selectedObjects.value );

            dsRooms.selectionControl.selectAll(false);
            props.loadDataObject ? props.loadDataObject.load() : null;
            alert( $t("Rooms added to lease"), ToastType.Info, { autohide: true});
        }
        catch (_) { }
    }

    const modelSelected = (pRow) => {
        vModel_ID.value = pRow.ID;
        updateWhereClause();
        pRow.ID ? vShow.value = true : vShow.value = false;
    };

    // props.disableContextFilter ? null : dsModels.enableContextFilter();
    dsRooms.filterObject.setColumnDistinctOptions( "LeaseHolders", {viewName : "aviw_Lease_LeasesObjects", distinctColumn : "OrgUnit", existsBinding : "T1.Object_ID = T2.CopyOrID"});
    dsRooms.filterObject.setColumnDistinctOptions( "UsedByOrgUnits", {viewName : "aviw_Lease_LeasesObjects", distinctColumn : "UsedByOrgUnit", existsBinding : "T1.Object_ID = T2.CopyOrID"});
    dsRooms.filterObject.setColumnDistinctOptions( "ClassificationsItem2", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "CodeAndTitle", existsBinding : "T1.ID = T2.ClassificationsItem2_ID"});
    dsClassificationLkp.filterObject.setColumnDistinctOptions( "ClassificationsItem2", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "CodeAndTitle", existsBinding : "T1.ID = T2.ClassificationsItem2_ID"});
    dsClassificationLkp.filterObject.setColumnDistinctOptions( "ClassificationsItem3", {viewName : "aviw_Assets_ClassificationsItems", distinctColumn : "Title", existsBinding : "T1.ID = T2.ClassificationsItem3_ID"});
</script>

<template>
    <ORowContainer app-container>
        <ORowContainer height="100%">
            <!-- Rooms Grid -->
            <ODataGrid :dataObject="dsRooms" :header-title="$t('Rooms')" :detailIframe="`/nt/room?ID=${dsRooms.current.ID}&HideNav=true`" collapseGridMenu ref="roomGrid">
                <template #cardheader>     
                    <OColContainer class="m-3 align-items-center gap-2">       
                        <div class="col-auto">            
                            <ODataLookup :dataObject="dsModels" class="form-control form-control-sm" :placeholder="$t('Select BIM-model...')" :value="dsModels.current.Name" :bind="modelSelected">
                                <OColumn field="ID" width="80"/>                
                                <OColumn field="Name" width="200"/>            
                            </ODataLookup>        
                        </div>
                        <!-- <div class="col-auto"> -->            
                            <!-- <ODatePicker class="form-control form-control-sm" id="roomStatusDate" format="Short Date" v-model="vRoomStatusDate" :callback="updateWhereClause" :showInputIcon="true" :clearable="false"/> -->        
                        <!-- </div> -->
                        <div class="col-auto">
                            <div class="dropdown">
                                <button class="btn btn-outline-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="bi bi-filter"></i> {{ $t("Filter") }} 
                                </button>

                                <div class="dropdown-menu">
                                    <div class="dropdown-item" v-if="!vHideStatusDate"><label for="roomStatusDate">{{ $t("Rooms Status Date") }}</label><ODatePicker class="form-control form-control-sm" id="roomStatusDate" format="Short Date" v-model="vRoomStatusDate" :callback="updateWhereClause" :showInputIcon="true" :clearable="false"/></div>
                                    <div class="dropdown-divider" v-if="!vHideStatusDate"></div>
                                    <label class="dropdown-item"><input type="checkbox" v-model="vHideUnavailable" style="margin-right: 5px;vertical-align: middle;">{{ $t("Hide unavailable rooms") }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="props.lease_ID">
                            <button class="btn btn-outline-primary btn-sm" @click="addSelectedRoomsToLease">
                                {{ $t("Add selected rooms to lease") }} ({{ dsRooms.selectionControl.selectedUniqueKeys.size }})
                            </button>
                        </div>
                    </OColContainer>
                </template>

                <OColumn field="ID" width="80" sortable :header-title="$t('ID')" :header-name="$t('ID')" v-slot="{ row }" pinned="left">    <a :href="`/nt/room?ID=${ row.ID }`" target="_blank"> {{ row.ID }} </a></OColumn>
                <OColumn field="Created" width="110" hide sortable/>
                <OColumn field="Updated" width="110" hide sortable/>
                <OColumn field="OrgUnit" bulkUpdate editable distinct width="200" sortable v-slot:editor="{modelValue: row}">
                    <OOrgUnitsLookup :bind="sel=>{row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name; row.AccessIdPath = sel.AccessIdPath; row.OrgUnitIdPath = sel.IdPath}">            
                        <template #orgunit>
                            <input type="text" id="orgUnitInput" v-model="row.OrgUnit">            
                        </template>    
                    </OOrgUnitsLookup>
                </OColumn>  
                <OColumn field="ParentOrgUnit" :header-name="$t('Parent OrgUnit')" distinct hide width="200" sortable/>
                <OColumn field="Domain" :header-name="$t('Domain')" distinct hide width="200" sortable/>
                <OColumn field="Name" editable width="160" :header-name="$t('Name')" pinned="left" sortable/>
                <OColumn field="Description" editable width="180" :header-name="$t('Description')" sortable pinned="left"/>
                <OColumn field="Location" width="160" :header-name="$t('Location')" sortable distinct v-slot:editor="{row}" bulkUpdate editable hide> 
                    <OObjectsLookup :bind="sel=>{ row.Location = sel.Name; row.Location_ID = sel.ID}" :objectsWhereClause="'IsLocation=1'">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Location"> 
                        </template>
                    </OObjectsLookup>
                </OColumn>
                <OColumn field="Building" width="160" :header-name="$t('Building')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <OObjectsLookup :bind="sel=>{ row.Building = sel.Name; row.Building_ID = sel.ID}" :objectsWhereClause="'IsBuilding=1'">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Building"> 
                        </template>
                    </OObjectsLookup>
                </OColumn>
                <OColumn field="Storey" width="160" :header-name="$t('Storey')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <OObjectsLookup :bind="sel=>{ row.Storey = sel.Name; row.Storey_ID = sel.ID}">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Storey"> 
                        </template>
                    </OObjectsLookup>
                </OColumn>
                <OColumn field="Wing" width="160" :header-name="$t('Wing')" sortable distinct v-slot:editor="{modelValue: row}" bulkUpdate editable> 
                    <OObjectsLookup :bind="sel=>{ row.Wing = sel.Name; row.Wing_ID = sel.ID}">
                        <template #target="{ target }">
                            <input :ref="target" type="text" v-model="row.Wing"> 
                        </template>
                    </OObjectsLookup>
                </OColumn>
                <OColumn field="Component" width="160" :header-name="$t('Component')" hide sortable/> 
                <OColumn field="AssetCondition" editable width="120" :header-name="$t('Condition Level')" distinct v-slot:editor="{ row }" bulkUpdate sortable>
                    <ODataLookup :data-object="dsConditionLevelLkp" disableRecent v-model="row.AssetCondition" :bind="sel => { row.AssetCondition = sel.NameT; row.AssetCondition_ID = sel.ID; }">
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />        
                        <OColumn field="NameT" width="200" :header-title="$t('Name')" :header-name="$t('Name')" />        
                        <OColumn field="FCMRateFactor" width="110" :header-title="$t('Factor')" :header-name="$t('Factor')" />    
                    </ODataLookup>
                </OColumn>
                <OColumn field="DesignedCapacity" bulkUpdate editable width="140" :header-name="$t('Designed Capacity')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="ActualCapacity" bulkUpdate editable width="140" :header-name="$t('Actual Capacity')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="ClassificationsItem" sortable editable width="110" :header-name="$t('Classification')" distinct v-slot:editor="{ row }" bulkUpdate>    
                    <ODataLookup v-model="row.ClassificationsItem" :data-object="dsClassificationLkp" :bind="sel => { row.ClassificationsItem_ID = sel.ID; row.ClassificationsItem = sel.Name; }">        
                        <OColumn field="ClassificationsItem0" width="250" :header-name="$t('Classification 1')" />        
                        <OColumn field="ClassificationsItem1" width="250" :header-name="$t('Classification 2')" />        
                        <OColumn field="ClassificationsItem2" width="250" :header-name="$t('Classification 3')"/>        
                        <OColumn field="ClassificationsItem3" width="250" :header-name="$t('Classification 4')" />    
                    </ODataLookup>
                </OColumn>
                <OColumn field="ClassificationsItem0" width="140" :header-name="$t('Main Function')" :cellTitle="row => row.ClassificationsItem0Description" sortable/>
                <OColumn field="ClassificationsItem1" width="140" :header-name="$t('Partial Function')" :cellTitle="row => row.ClassificationsItem1Description" sortable/>
                <OColumn field="ClassificationsItem2" width="140" :header-name="$t('Room Name')" :cellTitle="row => row.ClassificationsItem2Description" sortable/>
                <OColumn field="ClassificationsItem3" width="140" :header-name="$t('Room Specification')" :cellTitle="row => row.ClassificationsItem3Description" sortable/>
                <OColumn field="GLN" editable width="90" :header-name="$t('GLN')" hide sortable/>
                <OColumn field="GTIN" editable width="90" :header-name="$t('GTIN')" hide sortable/>
                <OColumn field="GIAI" editable width="90" :header-name="$t('GIAI')" hide sortable/>
                <OColumn field="GRAI" editable width="90" :header-name="$t('GRAI')" hide sortable/>
                <OColumn field="GrossArea" format="1 234.12" bulkUpdate editable width="110" :header-name="$t('Gross Area')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="NetArea" bulkUpdate editable width="110" format="1 234.12" :header-name="$t('Net Area')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="AreaType" editable bulkUpdate width="110" :header-name="$t('Area Type')" distinct v-slot:editor="{ row }" sortable>
                    <ODataLookup v-model="row.AreaType" :data-object="dsAreaTypeLkp" :bind="sel => { row.AreaType_ID = sel.ID; row.AreaType = sel.Name }">
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />
                        <OColumn field="Name" width="300" :header-title="$t('Name')" :header-name="$t('Name')" />
                        <OColumn field="IsLeasable" width="90" :header-name="$t('Is Leasable')" />
                        <OColumn field="IsCommonAreaBuilding" width="200" :header-name="$t('Is Common Area (Building)')" :header-title="$t('Cost for this area type will be distributed to lessees in the whole building. Example: reception in a building')"/>
                        <OColumn field="IsCommonAreaStorey" width="200" :header-name="$t('Is Common Area (Storey)')" :header-title="$t('Cost for this area type will be distributed to the lessees in the relevant storey. Example: meeting room in a storey')"/>
                    </ODataLookup>
                </OColumn> 
                <OColumn field="IsLeasable" width="90" :header-name="$t('Is Leasable')"/>
                <OColumn field="IsCommonAreaBuilding" width="180" :header-name="$t('Is Common Area Building')" hide :header-title="$t('Is Common Area Building. Cost for this area type will be distributed to lessees in the whole building. Example: reception in a building')"/>
                <OColumn field="IsCommonAreaStorey" width="180" :header-name="$t('Is Common Area Storey')" hide :header-title="$t('Is Common Area Storey. Cost for this area type will be distributed to the lessees in the relevant storey. Example: meeting room in a storey')"/>
                <OColumn field="PriceModel" bulkUpdate editable width="125" :header-name="$t('Price Model')" distinct v-slot:editor="{ row }" sortable>    
                    <ODataLookup v-model="row.PriceModel" :data-object="dsPriceModelLkp" :bind="sel => { row.PriceModel_ID = sel.ID; row.PriceModel = sel.Name; }">
                        <OColumn field="Name" width="300" :header-title="$t('Name')" :header-name="$t('Name')" />
                    </ODataLookup>
                </OColumn> 
                <OColumn field="RateCategory" width="150" :header-name="$t('Rate Category Name')" bulkUpdate editable distinct v-slot:editor="{ row }" sortable>
                    <ODataLookup v-model="row.RateCategory" :data-object="dsRateCategoryLkp" :bind="sel => { row.RateCategory_ID = sel.ID; row.RateCategory = sel.Name; row.RateCategoryRate = sel.Rate; }" disableRecent>
                        <OColumn field="ID" width="80" :header-title="$t('ID')" :header-name="$t('ID')" />    
                        <OColumn field="Name" width="200" :header-title="$t('Name')" :header-name="$t('Name')" />    
                        <OColumn field="Rate" width="100" :header-title="$t('Rate')" :header-name="$t('Rate')" format="1 234"/>    
                    </ODataLookup>
                </OColumn>
                <OColumn field="RateCategoryRate" width="110" :header-name="$t('Rate Category')" format="1 234" class="text-end" summaryAggregate="SUM" sortable hide/>
                <OColumn field="SuggestedRate" width="110" :header-name="$t('Suggested Rate')" format="1 234" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="IsLeased" width="80" :header-name="$t('Is Leased')" sortable/> 
                <OColumn field="LeaseHolders" width="150" :header-name="$t('Lease Holders')" sortable/>
                <OColumn field="UsedByOrgUnits" width="150" :header-name="$t('Used By OrgUnits')" sortable/>
                <OColumn field="LeaseLastStartDate" width="150" :header-name="$t('Lease Start Date')" sortable format="Short Date"/>
                <OColumn field="LeaseLastEndDate" width="150" :header-name="$t('Lease End Date')" sortable format="Short Date"/>
                <OColumn field="NetAreaUsed" format="1 234.12" width="150" :header-name="$t('Net Area Used')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="CapacityUsed" format="1 234.12" width="150" :header-name="$t('Capacity Used')" class="text-end" summaryAggregate="SUM" sortable/>
                <OColumn field="AreaUsedPercentage" width="150" :header-name="$t('Area Used %')" format="%" class="text-end" sortable/>
                <OColumn field="Availability" width="150" :header-name="$t('Availability %')" format="%" class="text-end" sortable/>
                <OColumn field="SuppliedBy" width="150" :header-name="$t('Contract (Supplied By)')" :header-title="$t('Supplied By Contract')" v-slot:editor="{modelValue: row}" bulkUpdate editable distinct sortable>
                    <OOrgUnitsLookup :bind="sel=>{row.SuppliedBy_ID = sel.ID; row.SuppliedBy = sel.Name;}">            
                        <template #orgunit> 
                            <input type="text" id="orgUnitInput" v-model="row.SuppliedBy">
                        </template>    
                    </OOrgUnitsLookup>
                </OColumn>
                <OColumn field="ContractActualEnd" width="150" :header-name="$t('Contract Actual End')" format="Short Date" sortable/>  
            </ODataGrid>
        </ORowContainer>

        <!-- BIM-viewer -->
        <OSizerPanel :class="vShow ? '' : 'd-none'" height="60%" maxHeight="90%" collapse id="main-sizerpanel-1">
            <ORowContainer class="mt-2">
                <div class="row mb-1">    
                    <OColContainer class="align-items-center gap-2">
                        <div class="dropdown" style="display: contents;">
                            <button class="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span v-if="vBimGroupByField">{{ $t('Group By') }}: {{ vBimGroupByField }}</span>
                                <span v-else>{{ $t('No grouping') }}</span>            
                            </button>            
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = null; vBimGroupByFieldVal = null;">{{ $t('No grouping') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 1'); vBimGroupByFieldVal = 'ClassificationsItem0Title';">{{ $t('Classification 1') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 2'); vBimGroupByFieldVal = 'ClassificationsItem1Title';">{{ $t('Classification 2') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 3'); vBimGroupByFieldVal = 'ClassificationsItem2Title';">{{ $t('Classification 3') }}</a></li>                
                                <!-- <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Classification 4'); vBimGroupByFieldVal = 'ClassificationsItem3Title';">{{ $t('Classification 4') }}</a></li> -->
                                <!-- <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Condition Level'); vBimGroupByFieldVal = 'AssetCondition';">{{ $t('Condition Level') }}</a></li> -->
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Lease Holders'); vBimGroupByFieldVal = 'LeaseHolders';">{{ $t('Lease Holders') }}</a></li>
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('OrgUnit'); vBimGroupByFieldVal = 'OrgUnitName';">{{ $t('OrgUnit') }}</a></li>
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Used By OrgUnits'); vBimGroupByFieldVal = 'UsedByOrgUnits';">{{ $t('Used By OrgUnits') }}</a></li>
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Storey'); vBimGroupByFieldVal = 'Storey';">{{ $t('Storey') }}</a></li>                
                                <li><a class="dropdown-item" href="#" @click="vBimGroupByField = $t('Area Type'); vBimGroupByFieldVal = 'AreaType';">{{ $t('Area Type') }}</a></li>            
                            </ul>        
                        </div>
                        <div class="ml-4" v-html="vGroupColors"></div>    
                    </OColContainer>
                </div>
                <BIMViewer :vDS="dsRooms" :vModelID="dsModels.current.ID" :vFieldName="vBimGroupByFieldVal" :vFilter="(props.disableContextFilter ? '' : `AccessIdPath LIKE '${context.idPath}%' AND `) + dsRooms.recordSource.whereClause + (dsRooms.recordSource.filterString ? ' AND ' + dsRooms.recordSource.filterString : '')" :vSelectedObjectIDs="JSON.stringify(dsRooms.selectionControl.selectedRows.map(e => {return {ID: e.ID}}))" @group-colors-added="(n) => vGroupColors = n"/>
            </ORowContainer>
        </OSizerPanel>
    </ORowContainer>
</template>